import {Spinner, HStack, Image, Box} from 'platform/foundation';

/**
 * Initial HTML document (apps/digital-certificate/src/index.html)
 * contains duplicate of this component.
 * Always check if this component is identical
 * to the one in the initial html.
 */
export function Loading() {
  return (
    <HStack align="center" justify="center" minHeight="100vh" minWidth="100vw">
      <Box padding={3}>
        <HStack spacing={4} align="center" justify="center">
          <Image
            src="./assets/images/logo.svg"
            alt="Digital Certificate"
            height={12}
            width="auto"
          />
          <Spinner size="large" />
        </HStack>
      </Box>
    </HStack>
  );
}
